import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IOperatorNode } from '../../../common/model/graphql-types';
import { OperatorIcon } from './operator-icon';

interface IProps {
  id?: string;
  slug?: string;
  enablePopover?: boolean;
  showLabel?: boolean;
  hideTags?: boolean;
  mode: string;
  unitId?: string;
}

export const Operator: React.FC<IProps> = ({
  id,
  unitId,
  mode,
  showLabel,
  slug,
  enablePopover
}) => {
  const data = useStaticQuery(graphql`
    query {
      allOperators: allContentfulOperator {
        nodes {
          id
          unitId
          fullName
          slug
          shortName
          regions
          rarity
          pvpScore
          pveScore
          pveScoreNumber
          pvpScoreNumber
          showNewTag
          areTranslationsUnnoficial
          areRatingsPending
          smallAvatar {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);
  if ((!id && !slug && !unitId) || !mode) {
    return <div>Err</div>;
  }

  const operator = data.allOperators.nodes.find((emp: IOperatorNode) =>
    id ? emp.id === id : unitId ? emp.unitId === unitId : emp.slug === slug
  );

  if (!operator) {
    return <div>Err</div>;
  }

  return (
    <>
      {mode === 'icon' && (
        <OperatorIcon
          operator={operator}
          showLabel={showLabel}
          enablePopover={enablePopover}
        />
      )}
    </>
  );
};
