import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Table } from 'react-bootstrap';

import '../../generic-page.scss';
import { Operator } from '../../../modules/cs/common/components/operator';

const CSOperatorGuidePage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Operators and you</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/guide_operators.png"
            alt="Operators and you"
          />
        </div>
        <div className="page-details">
          <h1>Operators and you</h1>
          <h2>This guide explains who are Operators and how to use them.</h2>
          <p>
            Last updated: <strong>05/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          This article is written with a very long-term perspective on account
          progression. This will make more sense by the time you’re done reading
          this. For now, understand that:{' '}
          <strong>
            Operators are not required to clear general PVE content.
          </strong>
        </p>
        <p>
          Unless noted otherwise, operators are all assessed at max level
          (8/11). Operator Command skills (with few exceptions) scale heavily
          with levels. The impact of operators on overall team performance is
          almost non-existent without levels. Coupled with the exceedingly high
          cost involved in maxing operators, players are heavily encouraged to
          plan their operators well in advance and commit to building only one
          at a time.
        </p>
        <SectionHeader title="What are Operators?" />
        <p>
          Operators support your team from the sidelines with their active and
          passive effects. An operator can be broken down into their three
          constituent parts to explain how they contribute to the team:
        </p>
        <h5>Operator level</h5>
        <StaticImage
          src="../../../images/counterside/generic/op_4.webp"
          alt="Operator banner"
        />
        <ul>
          <li>
            Operator stats are applied to the ship (HP%, DEF%, ATK%, Skill
            Haste%),
          </li>
          <li>
            The HP%, DEF%, ATK% stats increase with Operator level. Skill Haste%
            does NOT increase with operator level. Operator level can largely be
            ignored.
          </li>
        </ul>
        <h5>Command (Active) Skill</h5>
        <StaticImage
          src="../../../images/counterside/generic/op_5.webp"
          alt="Operator banner"
        />
        <ul>
          <li>
            Operators have unique Command skills that can be triggered by
            deploying characters in a specific sequence,
          </li>
          <li>
            Different characters can be deployed between characters of the
            chain. The chain will time out if it is not progressed. This timer
            can be reset by progressing the operator proc,
          </li>
          <li>
            The Command Skill is leveled up by feeding dupes of the operator
            into itself.
          </li>
        </ul>
        <h5>Side (Passive) Skill</h5>
        <StaticImage
          src="../../../images/counterside/generic/op_6.webp"
          alt="Operator banner"
        />
        <ul>
          <li>
            Operators come with a random Side skill from a common pool shared by
            all operators,
          </li>
          <li>
            The side skill applies a stat buff to all friendly units on the
            field. The side skill is always active,
          </li>
          <li>
            The side skill is leveled up by feeding other operators with the
            same side skill, with a chance of success depending on the rarity of
            the operator fodder,
          </li>
          <li>
            The side skill can be changed by feeding an operator of the same
            rarity or higher, with a chance of success depending on the rarity
            of the operator fodder.
          </li>
        </ul>
        <SectionHeader title="Getting SSR Operators" />
        <p>
          SSR Operators can be pulled from Operator Recruitment gacha banners
          and purchased directly from the Planet Mall using Task Planet Points.
          When starting out, it is heavily advised to spend your pulls on
          Employee banners and use the planet points to slowly accumulate your
          operator dupes.
        </p>
        <StaticImage
          src="../../../images/counterside/generic/op_1.jpg"
          alt="Operator banner"
        />
        <StaticImage
          src="../../../images/counterside/generic/op_2.jpg"
          alt="Operator shop"
        />
        <p>
          Operators gacha uses the same resources (Blue tickets / Quartz) as
          Employee gacha. The primary purpose of the operator banner is to get
          “operator fodder”. Operator fodder is a term used to describe low
          rarity operators with desirable side skills to feed into your main
          operators. Operator banners are the endgame blue ticket sink. Avoid
          them until your account is developed and you understand what you’re
          getting into.
        </p>
        <p>
          While it ultimately depends on the player and their endgame account
          direction, this table briefly goes over gacha and planet point
          priorities:
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Account Progression</th>
              <th>Pull</th>
              <th>Buy</th>
              <th>Rationale</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>
                  <span>Early</span>
                </p>
              </td>
              <td>
                <p>
                  <span>Employee</span>
                </p>
              </td>
              <td>
                <div className="employee-container">
                  <Operator unitId="30601" mode="icon" enablePopover />
                </div>
              </td>
              <td>
                <p>
                  <span>
                    For new players, the top priority is getting their first
                    copy of Kim Hana.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Mid</span>
                </p>
              </td>
              <td>
                <p>
                  <span>Employee</span>
                </p>
              </td>
              <td>
                <p>
                  <span>Operator</span>
                </p>
              </td>
              <td>
                <p>
                  <span>
                    Catch up on relevant PVE/ PVP SSR&rsquo;s and get your first
                    operator built.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Late</span>
                </p>
              </td>
              <td>
                <p>
                  <span>Operator</span>
                </p>
              </td>
              <td>
                <p>
                  <span>Employee / Operator</span>
                </p>
              </td>
              <td>
                <p>
                  <span>
                    Employee recruitment as needed for important SSR debut
                    banners. You need an absurd amount of operator fodder to
                    raise up various operator side skills.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <p>
                  <strong>
                    <span>A Note on DC:</span>
                  </strong>
                  <span>
                    &nbsp;For people looking to become competitive Danger Close
                    players, it should be noted that planet points are very
                    frequently spent on unit dupes. Tactical updates have a very
                    pronounced effect in high end competitive environments.
                  </span>
                </p>
                <p>
                  <span>
                    Ultimately, how a player spends their planet points and
                    pulls in endgame is heavily dependent on what they want to
                    achieve. Players at this point should know how to manage
                    their account. This note is meant to provide newer players a
                    glimpse into the mindset of endgame pull prioritization.
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="A Note on Side Skills" />
        <p>
          Although the unique Command skill is the most eye-catching part of
          operators, equal attention should be given to the side skill. The
          passive side skill stat buffs are highly relevant to team performance.
          In PVP, a level 1 AoE DMG RDC operator can perform better than an SSR
          operator with a bad side skill.
        </p>
        <p>
          PVP and DC are approached with radically different mindsets. There is
          no overlap with desirable side skills. Although players may likely
          identify themselves as “PVE” or “PVP” players, the first major
          decision doesn’t occur until they start to max their first operator.
        </p>
        <h5>PVP</h5>
        <p>
          In PVP, the top priority is keeping units alive. Characters cannot
          contribute value when they are dead. Being unable to match your
          opponent’s tankiness puts you at a massive disadvantage because it
          means your units will die before theirs and this creates a snowball
          effect that loses you games. PVP operators use:
        </p>
        <ul>
          <li>
            <strong>AoE DMG RDC</strong>: AoE DMG RDC is a category 2 buff (see
            also the Terms and mechanics guide) that mitigates all sources of
            AoE DMG. Any attack with more than one valid hit is considered AoE
            DMG. The vast majority of units in this game deal AoE DMG.
          </li>
          <li>
            <strong>Decrease Ground DMG</strong>: Decrease Ground DMG is a
            category 1 buff that mitigates all damage from ground-based damage
            dealers. The primary tradeoff is that Decrease Ground DMG applies to
            single target damage, which can make the soldier/ mech
            (single-target damage oriented comps) matchup more bearable at the
            expense of being much more vulnerable to fliers (i.e. ARosaria).
          </li>
        </ul>
        <p>
          AoE DMG RDC is heavily preferred because it almost always has better
          coverage. However, because of the absurd costs involved in raising two
          level 11 operators of the same side skill, you will ultimately end up
          with one level 11 AoE DMG RDC and one level 11 Decrease Ground DMG.
          Which side skill goes on which operator will largely come down to luck
          and whatever internal rationalization you come up with.
        </p>
        <h5>PVE</h5>
        <p>
          In PVE, the top priority is squeezing more damage out of your team to
          hasten clear speed against DC bosses. Danger Close operators use:
        </p>
        <ul>
          <li>
            <strong>Increase ASPD</strong>: ASPD side skill is highly valued in
            Danger Close, due to the presence of an optional -40% ASPD risk that
            can be picked for higher scores. ASPD not only provides more utility
            than the next best damage side skill (Increase CRIT DMG), it also
            comes out to more team-wide damage than CRIT DMG against the vast
            majority of Danger Close bosses.{' '}
          </li>
          <ul>
            <li>
              The ASPD side skill is required to clear bosses’ hit check
              mechanics (e.g. Tyrant Armor) without making concessions in team
              building or reducing difficulty modifiers (i.e. -40% Decreased
              ASPD debuff tag).{' '}
            </li>
            <li>
              ASPD also affects “animation speed”, so increasing ASPD can
              decrease the casting animation times on skills.
            </li>
            <li>
              ASPD also greatly affects units who have abilities that are based
              on attack count, like Chifuyu’s DEF shred, Gaeun’s enhanced
              attack, and Sky’s 30% ATK and 15% DMG RDC buff.
            </li>
          </ul>
          <li>
            <strong>Increase CRIT DMG</strong>: CRIT DMG is a direct buff to
            damage which can be better than ASPD, depending on the units used
            for a particular boss.
          </li>
          <li>
            If the core units used for a particular boss already have enough
            ASPD (e.g. Xiao Lin with a maxed EE), then CRIT DMG could
            potentially be better than ASPD.
          </li>
          <li>
            If the boss also does not use units which are heavily dependent on
            ASPD for their abilities, like Chifuyu’s DEF shred, CRIT DMG can
            also be better.
          </li>
          <li>
            Lastly, CRIT DMG also provides more damage than ASPD, which can be
            useful for bursting down bosses with units that have extremely
            strong one shot capabilities.
          </li>
        </ul>
        <SectionHeader title="Relevant Operators" />
        <p>
          An invested operator is only “needed” when you’re trying to seriously
          compete in Counterside’s ranked game modes. These are Ranked Gauntlet
          (PVP) and Danger Close (PVE).{' '}
        </p>
        <p>
          The table below details first operator considerations and side skills:
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th colSpan={2}>Ranked</th>
              <th colSpan={2}>Danger Close</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>
                  <span>Priority Operators</span>
                </p>
              </td>
              <td>
                <p>
                  <span>Desirable Side Skills</span>
                </p>
              </td>
              <td>
                <p>
                  <span>Priority Operators</span>
                </p>
              </td>
              <td>
                <p>
                  <span>Desirable Side Skills</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <div className="employee-container">
                  <Operator unitId="34101" mode="icon" enablePopover />
                  <Operator unitId="32713" mode="icon" enablePopover />
                </div>
              </td>
              <td>
                <p>
                  <span>AoE DMG RDC</span>
                </p>
                <p>
                  <span>Decrease Ground DMG</span>
                </p>
              </td>
              <td>
                <div className="employee-container">
                  <Operator unitId="33101" mode="icon" enablePopover />
                  <Operator unitId="32713" mode="icon" enablePopover />
                </div>
              </td>
              <td>
                <p>
                  <span>Increase ASPD</span>
                </p>
                <p>
                  <span>Increase CRIT DMG</span>
                </p>
              </td>
            </tr>
          </tbody>
        </Table>
        <p>
          Certain operators will perform better in specific situations e.g. Kim
          Hana has a niche within PVP. Although these operators have their uses,
          they are too specific to make for a good first operator.
        </p>
        <div className="employee-container">
          <Operator unitId="34101" mode="icon" enablePopover />
        </div>
        <h4>Sysop</h4>
        <p>
          {' '}
          Sysop forgoes a traditional operator buff/ debuff to generate 2 DP to
          accelerate unit deployment. His 2 DP refund provides a massive amount
          of tempo that can be used to cycle units faster and get more
          characters on the field. Sysop is incredibly flexible in what his 2 DP
          can do for the team. Some consideration will need to be made regarding
          the striker in his operator proc order; from the pool of PVP-relevant
          strikers, only a few prefer Sysop to Vivian (e.g. Rearm Titan).
        </p>
        <div className="employee-container">
          <Operator unitId="32713" mode="icon" enablePopover />
        </div>
        <h4>Sasha</h4>
        <p>
          {' '}
          Sasha acts a more offense-oriented Sysop-equivalent, becoming the
          operator of choice for more damage-focused rush comps. Sasha’s
          combination of damage buffs and DP refund generate a massive amount of
          tempo for rush comps that give them the extra push they need to
          overwhelm the enemy team.
        </p>
        <div className="employee-container">
          <Operator unitId="33101" mode="icon" enablePopover />
        </div>
        <h4>Vivian Rashford</h4>
        <p>
          In PVE, Vivian is one of the dominant operators for Danger Close,
          Vivian’s buffs significantly increase the damage ceiling for DC boss
          setups. Paired with characters like Curian and ASigma, Vivian’s buffs
          enable them to deal staggering amounts of burst damage. Vivian is a
          mainstay DC operator who sees use against all but a handful of bosses.
        </p>
        <p>
          Other (niche) operators sometimes see use, but they are far more
          situational and significantly less popular. It is difficult to
          emphasize how unviable most operators are.These operators represent
          the vast majority of operators used in their respective game modes.
        </p>
        <hr />
        <h5>Other Noteworthy Operators</h5>
        <div className="employee-container">
          <Operator unitId="30201" mode="icon" enablePopover />
        </div>
        <h4>Olivie Park</h4>
        <p>
          Olivie’s DMG RDC, sustain, and debuff immunity are a perfect
          combination of defensive stats. Olivie can be used to enable greedy
          starting deployments, protect against opponents’ burst damage setups,
          and cleanse problematic debuffs. Of note, Olivie is only compatible
          with counters and her awkward proc order restricts team building.
        </p>
        <div className="employee-container">
          <Operator unitId="31901" mode="icon" enablePopover />
        </div>
        <h4>Serina Crew</h4>
        <p>
          Serina’s on-demand buff block can severely hamper certain characters’
          ability to function. Big ones include Replacer King’s wind punches and
          Awakened Horizon’s invulnerability, but Serina can find plenty of
          value nullifying defensive/ offensive buffs in general. It is
          important to keep in mind that Serina is a “reactive” operator whose
          value is wholly dependent on the characters your opponents play.
        </p>
        <div className="employee-container">
          <Operator unitId="30601" mode="icon" enablePopover />
        </div>
        <h4>Kim Hana</h4>
        <p>
          Kim Hana is a universal operator that performs well in all content but
          especially excels in Danger Close where her DP refund and skill haste
          help accelerate unit deployments and cooldowns.
        </p>
        <hr />
        <SectionHeader title="Various Operator Tables and Rates" />
        <StaticImage
          src="../../../images/counterside/generic/op_3.jpg"
          alt="Operator banner"
        />
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Level 1</th>
              <th>Level 11</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Decrease Air DMG</td>
              <td>Ally damage taken from Air units</td>
              <td>-5%</td>
              <td>-20%</td>
            </tr>
            <tr>
              <td>Decrease Ground DMG</td>
              <td>Ally damage taken from Ground units</td>
              <td>-5%</td>
              <td>-20%</td>
            </tr>
            <tr>
              <td>Decrease Ranged DMG</td>
              <td>Ally damage taken from Ranged units</td>
              <td>-7,5%</td>
              <td>-25%</td>
            </tr>
            <tr>
              <td>Decrease Melee DMG</td>
              <td>Ally damage taken from Melee units</td>
              <td>-7,5%</td>
              <td>-25%</td>
            </tr>
            <tr>
              <td>Decrease AoE DMG</td>
              <td>Ally damage taken from AoE attacks</td>
              <td>-10%</td>
              <td>-20%</td>
            </tr>
            <tr>
              <td>Increase HIT</td>
              <td>Ally HIT increased</td>
              <td>+130</td>
              <td>+350</td>
            </tr>
            <tr>
              <td>Increase EVA</td>
              <td>Ally EVA increased</td>
              <td>+130</td>
              <td>+280</td>
            </tr>
            <tr>
              <td>Increase ASPD</td>
              <td>Ally ASPD increased</td>
              <td>+2%</td>
              <td>+12%</td>
            </tr>
            <tr>
              <td>Increase SPD</td>
              <td>Ally SPD increased</td>
              <td>+7,5%</td>
              <td>+25%</td>
            </tr>
            <tr>
              <td>Increase Role AVD DMG</td>
              <td>Ally Advantage Role DMG increased</td>
              <td>+10%</td>
              <td>+50%</td>
            </tr>
            <tr>
              <td>Increase CRIT DMG</td>
              <td>Ally CRIT DMG increased</td>
              <td>+15%</td>
              <td>+30%</td>
            </tr>
            <tr>
              <td>CRIT DMG RES</td>
              <td>Ally CRIT DMG Res increased</td>
              <td>+12,5%</td>
              <td>+30%</td>
            </tr>
          </tbody>
        </Table>
        <StaticImage
          src="../../../images/counterside/generic/op_7.webp"
          alt="Operator banner"
        />
      </div>
    </DashboardLayout>
  );
};

export default CSOperatorGuidePage;

export const Head: React.FC = () => (
  <Seo
    title="Operators guide | Counter Side | Prydwen Institute"
    description="Guide for the Operators in Counter Side."
  />
);
